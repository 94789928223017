/* ##### Service Area CSS ##### */

.HomeDemo2 .service_single_content {
    position: relative;
    z-index: 1;
    padding: 70px 46px 55px;
    background: #1a1a1d;
    box-shadow: 0 24px 65px 0 rgb(0 0 0 / 64%);
    margin-bottom: 30px;
}

.HomeDemo2 .service_single_content .service_icon i {
    font-size: 30px;
    margin-bottom: 20px;
    color: #fff;
    display: block;
}
.HomeDemo2 .service_icon{
    margin-bottom: 20px
}
.HomeDemo2 .service_single_content h6 {
    margin-bottom: 15px;
    color: #fff;
    font-size: 24px;
}